import {Component, Inject, OnInit} from '@angular/core';
import {BroadcastClientResponse} from "../../models/content-management/broadcastClientResponse";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContentResponse} from "../../models/content-management/contentResponse";
import {ContentRequest} from "../../models/content-management/contentRequest";
import {Configuration} from "../../models/configuration";
import { EntityResponse } from '../../models/content-management/entityResponse';

@Component({
  selector: 'urban-content-dialog',
  templateUrl: './content-dialog.component.html',
  styleUrls: ['./content-dialog.component.scss']
})
export class ContentDialogComponent implements OnInit {
  private contentResponse: ContentResponse;
  private contentRequest: ContentRequest;
  public isAdd: boolean = true;
  public clients: BroadcastClientResponse[] = [];
  public fileError: string;
  public appConfig: Configuration;
  private file: File;
  public acceptedFileExtensions: string;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    duration: [{value: 0, disabled: false}, Validators.required],
    tags: [{value: '', disabled: true}],
    broadcastClient: [{value: 0, disabled: false}, Validators.required],
    isPublic: [{value: "", disabled: false}, Validators.required]
  });

  public error: string | null;
  public fileName: string | null;
  private base64Content: string | null;
  constructor(
    public dialogRef: MatDialogRef<ContentDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        content: ContentResponse,
        entities: EntityResponse[],
        broadcastClients: BroadcastClientResponse[],
        appConfig: Configuration,
        isAdmin: boolean
      }
  ) {
  }

  ngOnInit(): void {
    this.clients = this.data?.broadcastClients;
    this.appConfig = this.data?.appConfig;

    if(this.appConfig.Settings.ContentManagementAllowedExtensions){
      this.acceptedFileExtensions = this.appConfig.Settings.ContentManagementAllowedExtensions.toString();
    }

    if (this.data) {
      if (this.data.entities?.length > 0) {
        this.form.controls.tags.enable();
      }

      if (this.data.content) {
        this.contentResponse = this.data.content;
        this.isAdd = false;
        this.form.controls.name.setValue(this.contentResponse.Name);
        this.form.controls.duration.setValue(this.contentResponse.Duration);
          this.form.controls.tags.setValue(this.contentResponse.Tags);
        this.form.controls.broadcastClient.setValue(this.contentResponse.BroadcastClientId);
        this.form.controls.isPublic.setValue(this.contentResponse.IsPublic.toString());
      }
    }
  }

  addition(): void {
    if (!this.form.valid) {
      this.error = 'GENERAL.ERROR_EMPTY';
      return;
    }

    if (this.file === undefined) {
      this.error = 'CONTENT_MANAGEMENT.ERROR_FORM_DATA';
      return;
    }

    this.contentRequest = {
      id: this.contentResponse?.Id,
      broadcastClient: this.form.controls.broadcastClient.value,
      name: this.form.controls.name.value,
      tags: this.form.controls.tags.value,
      duration: +this.form.controls.duration.value,
      isPublic: this.form.controls.isPublic.value === "true",
      file: {
        base64Content: this.base64Content,
        fileName: this.fileName,
        mimeType: this.file.type
      }
    };

    this.dialogRef.close({ request: this.contentRequest, file: this.file });
  }

  compileData(): void {
    this.contentRequest = {
      id: this.contentResponse?.Id,
      broadcastClient: this.form.controls.broadcastClient.value,
      name: this.form.controls.name.value,
      tags: this.form.controls.tags.value,
      duration: +this.form.controls.duration.value,
      isPublic: this.form.controls.isPublic.value === "true"
    };
    this.dialogRef.close({ request: this.contentRequest });

  }

  contentUpload(event: any): void {
    if (event.target.files[0].size > +this.appConfig.Settings.ContentManagementMaxFileSize) {
      this.fileError = 'CONTENT_SIZE_MAX';
    } else {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (_) => {
        if (_.loaded === _.total)
          this.base64Content = reader.result.toString().split(',')[1];
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    }
  }

  public goToEntities(): void {
    this.dialogRef.close({ goToEntities: true });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
