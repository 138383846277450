import { Component, Inject, OnInit } from '@angular/core';
import { Advertising, AdvertisingType } from '../../models/advertising/advertising';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddAdvertisingRequest } from '../../models/advertising/advertisingRequest';
import { Service } from '../../models/service/service';
import { Configuration } from '../../models/configuration';
import { PassDataService } from '../../services/pass-data/pass-data.service';
import { first } from 'rxjs/operators';
import { ImageInfo } from '../../models/imageInfo';

@Component({
  selector: 'urban-advertising-dialog',
  templateUrl: './advertising-dialog.component.html',
  styleUrls: ['./advertising-dialog.component.scss']
})
export class AdvertisingDialogComponent implements OnInit {
  public advertising: Advertising;
  public advertisingTypes: AdvertisingType[] = [];
  public services: Service[] = [];
  public error: boolean = false;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    type: [{ value: null, disabled: false }, Validators.required],
    message: { value: '', disabled: false },
    rangeDate: [{ value: null, disabled: false }, Validators.required],
    startTime: [{ value: null, disabled: false }, Validators.required],
    endTime: [{ value: null, disabled: false }, Validators.required],
    service: [{ value: '', disabled: false }, Validators.required]
  });
  public images: ImageInfo[];
  public minDateRange: Date;
  public appConfig: Configuration;
  public imageError: string;

  constructor(
    private passDataService: PassDataService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AdvertisingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        advertising: Advertising,
        types: AdvertisingType[],
        services: Service[]
      }
  ) { }

  ngOnInit(): void {
    this.advertising = this.data?.advertising;
    this.advertisingTypes = this.data?.types;
    this.services = this.data?.services;

    let now: Date = new Date();
    this.minDateRange = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let imagesInfo: ImageInfo[];

    if (this.advertising) {
      const dateRange = [this.advertising.StartDate, this.advertising.EndDate].map(ts => new Date(ts * 1000).toISOString());

      let formattedStartTime = new Date(now);
      let formattedEndTime = new Date(now);
      formattedStartTime.setHours(this.advertising.StartTime.Hours, this.advertising.StartTime.Minutes);
      formattedEndTime.setHours(this.advertising.EndTime.Hours, this.advertising.EndTime.Minutes);

      this.form.setValue({
        name: this.advertising.Name,
        type: this.advertising.Type.Id,
        message: this.advertising.Message,
        rangeDate: dateRange,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        service: this.advertising.Service.Id
      });

      try {
        imagesInfo = JSON.parse(this.advertising.Image);
      }
      catch (e) {}
    }
    this.images = imagesInfo ?? [];

    this.passDataService.appConfiguration$.pipe(first()).subscribe(config => {
      this.appConfig = config;
    });
  }

  public saveAdvertising(): void {
    if (this.advertisingTypes && this.form.valid) {
      this.error = false;

      const dateRange = this.form.get('rangeDate')?.value;
      let startDate: number;
      let endDate: number;

      if (dateRange) {
        const [startDateISO, endDateISO] = dateRange.map((date: string) => new Date(date));
        startDate = Math.floor(startDateISO.getTime() / 1000);
        endDate = Math.floor(endDateISO.getTime() / 1000);
      }

      let startTime: Date = new Date(this.form.get('startTime')?.value);
      let endTime: Date = new Date(this.form.get('endTime')?.value);
      let imagesString: string = JSON.stringify(this.images);

      const request: AddAdvertisingRequest = {
        Name: this.form.controls.name.value,
        Type: this.form.controls.type.value,
        Message: this.form.controls.message.value,
        Images: imagesString,
        StartDate: startDate,
        EndDate: endDate,
        StartTime: {
          Hours: startTime.getHours(),
          Minutes: startTime.getMinutes()
        },
        EndTime: {
          Hours: endTime.getHours(),
          Minutes: endTime.getMinutes()
        },
        Service: this.form.controls.service.value
      };

      this.dialogRef.close(request);
    }
    else {
      this.error = true;
    }
  }

  public imageUpload(event: any): void {
    event?.addedFiles?.forEach((file: File) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (myTarget) => {
        let image = new Image();
        image.src = myTarget.target.result.toString();
        image.onload = () => {
          let imageBaseData: string | ArrayBuffer = reader.result;
          let imageLink: string = window.URL.createObjectURL(new Blob([file], {type: file.type}));

          let newImage: ImageInfo = {
            Name: file.name,
            Base64: imageBaseData.toString(),
            DownloadLink: imageLink
          };
          this.images.push(newImage);
          this.imageError = null;
        }
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    });
  }

  public imageDownload(image: ImageInfo) {
    let imageLink: string = image.DownloadLink;
    let downloadLink = document.createElement('a');
    downloadLink.href = imageLink;
    downloadLink.download = image.Name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  public imageRemove(index: number): void {
    this.images.splice(index, 1);
  }
}
